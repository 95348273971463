import React from 'react'
import {Link, graphql, StaticQuery} from 'gatsby'
import { Location } from '@reach/router'
import './styles.sass'

const NavBar = ({toggleNavbar, isActive}) => (
  <StaticQuery
    query={graphql`
            query SearchIndexQuery {
                siteSearchIndex {
                    index
                }
            }
        `}
    render={data => (
      <Location>
        {({ navigate, location }) =>
          <nav className='navbar is-fixed-top' aria-label='main navigation'>
            <div className='navbar-brand'>
              <Link to='/' className='navbar-item'>
                <strong className='is-size-4'>Tm</strong>
              </Link>
              <button
                className={`button navbar-burger ${isActive ? 'is-active' : ''}`}
                data-target='navMenu'
                onClick={toggleNavbar}
              >
                <span />
                <span />
                <span />
              </button>
            </div>
            <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} id='navMenu'>

              <div className='navbar-end'>
                <Link
                  className={`navbar-item is-size-6 ${location.pathname === '/' && 'is-active'}`}
                  to='/'
                >
                            Hi Timo.
                </Link>
                <Link
                  className={`navbar-item is-size-6 ${location.pathname === '/blog' && 'is-active'}`}
                  to='/blog'
                >
                            Blog
                </Link>
                <Link
                  className={`navbar-item is-size-6 ${location.pathname === '/about' && 'is-active'}`}
                  to='/about'
                >
                            About
                </Link>
              </div>
            </div>
          </nav>
        }
      </Location>
    )}
  />
)

export default NavBar
