import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import '../../assets/sass/styles.sass'
import config from '../../../config'
import NavBar from '../NavBar'
import Footer from '../Footer'

class Layout extends Component {
  constructor (props) {
    super(props)
    this.state = {isActive: false}
    this.toggleNavbar = this.toggleNavbar.bind(this)
  }

  toggleNavbar () {
    this.setState({isActive: !this.state.isActive})
  }

  render () {
    return (
      <Fragment>
        <div className='app'>
          <Helmet>
            <title>{config.siteTitle}</title>
            <meta name='description' content={config.siteDescription} />
          </Helmet>
          <NavBar isActive={this.state.isActive} toggleNavbar={() => this.toggleNavbar()} />
          <Fragment>
            <section className='section'>
              {this.props.children}
            </section>
          </Fragment>
        </div>
        <div className='push-footer' />
        <Footer />
      </Fragment>
    )
  }
}

export default Layout
