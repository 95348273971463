module.exports = {
  siteTitle: 'Hi Timo', // Site title.
  siteTitleAlt: 'Hi Timo', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://hitimo.co', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'This is my personal homepage and blog. Have fun!', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  userName: 'Timo Müller',
  userTwitter: 'merte143',
  userLocation: 'Berlin, Germany',
  userDescription: '',
  copyright: '© Timo Müller 2019 - handcrafted with ❤️& 🥳', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
